.products-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
}
.product-title {
	text-align: center;
}
.images {
	display: flex;
	justify-content: space-around;
	height: 250px;
	width: 80%;
}
.product-image {
	margin-bottom: 30px;
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-sizing: border-box;
	opacity: 1.5;
	overflow: hidden;
}
.one {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('../assets/chacuterie.png');
}
.two {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('../assets/boeuf.jpg');
}
.three {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('../assets/taureau.jpg');
}
.four {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('../assets/ship.jpg');
}
.gras {
	font-size: 3rem;
}
.italic {
	font-size: 1.2rem;
	font-style: italic;
}
.one:hover,
.two:hover,
.three:hover,
.four:hover {
	border-radius: 20px;
	transition: 100ms ease;
	cursor: pointer;
	font-size: 1.2rem;
}
h3 {
	color: whitesmoke;
	border-top: 1px solid whitesmoke;
	border-bottom: 1px solid whitesmoke;
	width: 70%;
	text-align: center;
	padding: 10px;
}
@media only screen and (max-width: 600px) {
	.images {
		flex-direction: column;
		width: 95vw;
		height: 150vh;
	}
	.gras {
		font-size: 2rem;
	}
	.product-image {
		width: 100%;
		height: 100%;
	}
}
