.itemContainer {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 300px;
	width: 300px;
	margin: 0 5vw;
	justify-content: space-evenly;
	margin-top: 30px;
	box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}
.CharcuterieContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
}
.imgItem {
	overflow: hidden;
}
.row {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.hidden {
	display: none;
}

.itemContainer:hover {
	transform: scale(110%);
	cursor: pointer;
}
.itemContainer:hover + .hidden {
	display: block;
	margin-top: -10px;
}
