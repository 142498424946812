.image-background {
	background-image: url('../assets/slogon.jpg');
	height: 60vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	animation-name: animateBackground;
	animation-duration: 18s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-play-state: running;
	animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.banner-container {
	min-height: 30rem;
	background-color: #a41323;
	display: flex;
	align-items: center;
}
.banner-img {
	min-width: 50%;
	height: 100%;
	object-fit: cover;
}
.banner-text {
	width: 50%;
	padding: 0 2rem;
	color: white;
	flex-direction: column;
	font-size: 1.2rem;
}
.margin-top {
	margin-top: 5rem;
}
.banner-button {
	background-color: white;
	color: #a41323;
	border-radius: 1rem;
	padding: 0.5rem 0.7rem;
	width: fit-content;
	font-size: 1rem;
	margin-left: auto;
	font-style: normal;
	margin-right: 3rem;
}

@keyframes animateBackground {
	0% {
		background-image: url('../assets/slogon.jpg');
	}
	40% {
		background-image: url('../assets/viande.png');
		left: -1000px;
	}
	70% {
		background-image: url('../assets/viandee.jpg');
	}
}
@media only screen and (max-width: 600px) {
	.image-background {
		height: 30vh;
	}
}
