.container {
	height: 350px;
	background-color: rgb(250, 235, 215);
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}
.imageFooter {
	max-height: 150px;
}

.col {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

span {
	flex-direction: row;
	max-width: fit-content;
	display: flex;
	justify-content: baseline;
	align-items: center;
	margin-left: 10%;
	width: 100%;
	color: rgb(66, 65, 65);
	font-size: 0.9rem;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
p {
	margin-left: 0.5rem;
}
h2 {
	color: rgb(165, 42, 42);
}

a {
	text-decoration: none;
	color: rgb(66, 65, 65);
	transition: 100ms ease-out;
	margin-top: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

a:hover {
	transform: scale(120%);
	font-weight: 600;
}
.nomargin {
	margin-left: 0;
}

@media only screen and (max-width: 600px) {
	span {
		margin-left: 0%;
	}
	.container {
		display: flex;
		flex-direction: column;
		height: 40%;
	}
	.col {
		margin-left: 10%;
	}
	a:hover {
		transform: scale(100%);
	}
}
