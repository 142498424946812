.propo-img {
	min-height: 15rem;

	object-fit: cover;
	background-color: aqua;
	min-width: 100vw;
	box-sizing: border-box;
}
.postBanner {
	min-height: 10rem;
	min-width: 100vw;
	background-color: #a41323;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 1.2rem;
	align-items: center;
	padding: 1rem 20%;
	box-sizing: border-box;
}
.margin-right-auto {
	margin-right: auto;
	color: rgb(182, 173, 173);
}
.margin-top-2rem {
	margin-top: 2rem;
}
.bg-white {
	background-color: white;
}
.color-black {
	color: rgb(65, 63, 63);
}
.localisation {
	min-width: 100vw;
	max-height: 30rem;
	object-fit: cover;
}
.height-propos {
	min-height: 30rem;
}
