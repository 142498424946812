ul {
	display: flex;
	justify-content: space-evenly;
}
nav {
	display: flex;
	flex-direction: column;
}
.logo-head {
	max-height: 20vh;
}

li {
	color: #a41323;
	font-size: 1.2rem;
	list-style-type: none;
	transition: 100ms ease-out;
	padding: 0.3rem;
}

li:hover {
	transform: scale(130%);
	cursor: pointer;
}

img {
	display: flex;
	max-width: 15rem;
	align-self: center;
}
.active {
	border-top: solid;

	border-bottom: solid;
}

@media only screen and (max-width: 600px) {
	ul {
		display: flex;
		flex-direction: column;
	}
	li:hover {
		transform: scale(100%);
	}
}
